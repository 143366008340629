var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"course",attrs:{"color":_vm.cardColor,"max-width":_vm.maxWidth,"elevation":"2","rounded":"lg"}},[_c('v-img',{attrs:{"src":_vm.avatar,"max-height":"100"}},[(_vm.isRequirementFulfilled)?_c('v-container',{staticClass:"fill-height"},[_c('v-row',{attrs:{"align":"start"}},[_c('v-row',{attrs:{"justify":"end"}},[_c('div',{staticClass:"mr-8 mt-n8"},[_c('v-icon',[_vm._v("mdi-checkbox-marked-circle")])],1)])],1)],1):_vm._e()],1),(_vm.name)?_c('v-card-title',{staticClass:"d-inline-block text-overline text-center mb-6 mt-3",staticStyle:{"max-width":"437px"}},[_vm._v(_vm._s(_vm.name))]):_vm._e(),[(_vm.allFilesFulfilled)?_c('v-sheet',{staticClass:"d-flex justify-center",attrs:{"color":_vm.cardColor}},[_c('v-switch',{attrs:{"hide-details":"","label":"Editar"},model:{value:(_vm.fileSwitchOn),callback:function ($$v) {_vm.fileSwitchOn=$$v},expression:"fileSwitchOn"}})],1):_vm._e()],_c('v-card-text',{staticClass:"px-4"},[(_vm.isFileStored)?_c('v-sheet',{staticClass:"d-flex justify-center text-h6",attrs:{"color":_vm.cardColor}},[_c('v-btn',{attrs:{"plain":"","outlined":"","rounded":"","color":"identity","href":_vm.fileUrl,"target":"_blank"}},[_vm._v(_vm._s(_vm.fileName)+" "),_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-eye-check-outline")])],1)],1):_vm._e(),_c('v-file-input',{attrs:{"rules":[
        _vm.rules.verified(_vm.file, _vm.requirementById(_vm.requirementId)),
        _vm.rules.required
      ],"prepend-icon":_vm.uploadIcon,"hide-input":!_vm.fileSwitchOn && _vm.isFileStored,"chips":"","accept":"application/pdf","clearable":false,"disabled":_vm.allFilesFulfilled && !_vm.fileSwitchOn,"label":_vm.label,"loading":_vm.loading,"success":_vm.valid,"hint":"","persistent-hint":!_vm.loading},on:{"change":function($event){_vm.onChangeFile($event, _vm.requirementById(_vm.requirementId))},"update:error":function($event){_vm.valid = $event}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
      var text = ref.text;
return [_c('v-chip',{staticClass:"text-button px-3 py-5",class:_vm.statusCls(_vm.valid),attrs:{"color":_vm.statusColor(_vm.valid)}},[_vm._v(_vm._s(text)+" ")]),_c('br')]}}]),model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}},[_c('br'),_c('template',{slot:"progress"},[_c('br'),_c('v-progress-linear',{staticClass:"light-green--text text--darken-4",attrs:{"height":"25","color":"light-green lighten-2","rounded":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var value = ref.value;
return [_c('strong',[_vm._v(_vm._s(_vm.progressPercentage(value))+"%")])]}}]),model:{value:(_vm.uploadValue),callback:function ($$v) {_vm.uploadValue=$$v},expression:"uploadValue"}})],1)],2),_c('v-snackbar',{attrs:{"multi-line":_vm.multiLine},scopedSlots:_vm._u([{key:"action",fn:function(ref){
      var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"pink","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Cerrar ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" ¡Disculpa! Parece que no tienes permisos para subir este archivo ")])],1),(!_vm.isFileStored)?_c('v-card-actions',{staticClass:"px-8"},[_c('v-container',{staticClass:"ma-0 pa-0 mt-4",attrs:{"fluid":""}},[(_vm.universe)?_c('v-row',{staticClass:"mb-2",attrs:{"align":"center","no-gutters":""}},[_c('v-col',{staticClass:"text-right mr-2",attrs:{"cols":"6"}},[[_c('v-progress-circular',{attrs:{"rotate":360,"size":75,"width":15,"value":(_vm.completed + "%"),"color":_vm.semaphore}},[_vm._v(" "+_vm._s(_vm.completed)+"% ")])]],2),_c('v-col',{attrs:{"cols":"4"}},[_c('span',{staticClass:"text-subtitle-1 text--secondary text-left"},[_vm._v("lo han tomado")])])],1):_vm._e(),_c('v-row',{staticClass:"mt-4",attrs:{"justify":"space-around"}},[_c('span',{staticClass:"group"},[_vm._v(" Si aún no la tienes: ")]),_c('span',{staticClass:"group"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"href":_vm.link,"color":"identity","target":"_blank","rel":"noopener noreferrer","small":"","rounded":"","outlined":"","prepend-icon":"mdi-calendar"}},'v-btn',attrs,false),on),[_vm._v("Toma el curso")])]}}],null,false,2537942685)},[_c('span',[_vm._v("Abrir en nueva ventana")])])],1)]),_c('v-row',{attrs:{"justify":"space-around"}},[_c('span',{staticClass:"group"},[_c('div',{staticClass:"mt-3 mb-2 caption"},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-clock")]),_vm._v(" Duración "),_c('strong',[_vm._v(" "+_vm._s(_vm.hrs)+" horas")])],1)])])],1)],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }